import React from 'react';
import './header.css';
import people from '../../assets/people.png'
import ai from '../../assets/ai.png'
const Header = () => {
  return (
    <div className='gpt3__header section__padding' id='home'>
      <div className='gpt3__header-content'>
        <h1 className='gradient__text'>The Future is Here with GPT3 OpenAI</h1>
        <p>With GPT-3, the boundaries of what AI can achieve continue to expand, heralding a new era of intelligent computing and boundless possibilities. </p>
        <div className='gpt3__header-content__input'>
          <input type='email' placeholder='Your Email Address'/>
          <button type='button'> Get Started</button>
        </div>
        <div className='gpt3__header-content__people'>
          <img src = {people} alt='people'/>;
          <p>218 people visited in last 24 hours</p>
        </div>
      </div>
      <div className='gpt3__header-image'>
        <img src={ai} alt='ai'/>
      </div>
    </div>
  )
}

export default Header